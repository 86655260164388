<template>
  <div>
    <van-loading type="spinner" v-if="loading" />
    <van-nav-bar
      title=""
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div class="titleCont">
      <p class="title">{{ activityName }}</p>
      <p>{{ skuName }}</p>
    </div>
    <div class="moneyCont">
      <p>
        <span> {{ $t("withdraw.WithdrawalAmount") }}</span>
        <span class="money"> {{ points }} <span v-if="points">PYG</span></span>
      </p>
      <p>
        <span> {{ $t("withdraw.Withdrawnto") }}</span>
        <span> {{ bankAccount }}</span>
      </p>
    </div>
    <button class="btn" @click="sureWithdraw" :disabled="loading || !withdrawBtnShow">
      {{ $t("withdraw.sureWithdraw") }}
    </button>
    <div class="mengceng" v-show="mengcengshow"></div>
    <div class="cashoutFailBox" v-show="cashoutFailBoxshow">
      <p>{{ $t("withdraw.fail") }}</p>
      <p>{{failReason}}</p>
      <div class="textbtn" @click="closecashoutFailBox">
        {{ $t("withdraw.Done") }}
      </div>
    </div>
  </div>
</template>
<script>
import { preApply, apply } from "@network/api";
export default {
  name: "",
  data() {
    return {
      fromPath: "",
      loading: false,
      cashoutFailBoxshow: false,
      mengcengshow: false,
      failReason: "",
      recordNo: "",
      activityName: "SE首销季以旧换新挑战赛激励",
      skuName: "Reno5 Pro+ 5G PDRM00（256GB 12GB）全网通星河入梦",
      points: 30,
      bankAccount: "XXXX银行(2980)",
      withdrawBtnShow :false,
    };
  },
  mounted() {
    this.getPath();
  }, 
   beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      vm.fromPath = from.path;
    });
  },
  methods: {
    getPath() {
      if (this.$route.path == "/withdraw") {

        this.recordNo = this.$route.params.recordNo;
        this.points = this.$route.params.points;
        this.skuName = this.$route.params.skuName;
        this.activityName = this.$route.params.activityName;
        this.loading = true;
          if(Number(this.points)>0){
              this.withdrawBtnShow = true;
            }else{
              this.withdrawBtnShow = false;
            }
        preApply({})
          .then((res) => {
            this.loading = false;
            if(res.code === '10011'){
            return
          }
            if (res.code == "000000") {
              this.bankAccount = res.data.bankAccount;
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast(err.message);
          });

      }
    },
    back() {
      this.$router.back(-1);
    },
    sureWithdraw() {
      this.loading = true;
      apply({ verifyNo: this.recordNo, amount: this.points })
        .then((res) => {
          this.loading = false;
          if(res.code === '10011'){
            return
          }
          if (res.code == "000000") {
            if (res.data.status == "F") {
              this.failReason = res.data.statusDesc;
              this.cashoutFailBoxshow = true;
              this.mengcengshow = true;
            } else {
              this.$router.push({
                name: "withdrawResult",
                params: {
                  amount: res.data.amount,
                  bankAccount: res.data.bankAccount,
                  status: res.data.status,
                  createTime: res.data.createTime,
                  successTime: res.data.successTime,
                  fromPath: this.fromPath
                  // 传入 上一个页面
                },
              });
            }
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$toast(err.message);
        });
    },
    closecashoutFailBox() {
      this.cashoutFailBoxshow = false;
      this.mengcengshow = false;
    },
  },
};
</script>
<style scoped>
.titleCont {
  margin-top: 46px;
  padding: 30px 24px;
}
.titleCont p:nth-child(1) {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}
.titleCont p:nth-child(2) {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #999999;
}
.moneyCont {
  padding: 54px 24px;
}
.moneyCont p {
  margin: 0;
  line-height: 40px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.moneyCont > p:nth-child(1) {
  border-bottom: 1px solid #e7e7e7;
}
.moneyCont > p > span:nth-child(1) {
  font-size: 16px;
  color: #999;
  display: inline-block;
    width: 145px;
}
.moneyCont > p > span:nth-child(2) {
  text-align: right;
      flex: 1;
}
.moneyCont > p:nth-child(2) {
  line-height: 28px;
    height: auto;
    margin-top: 5px;
}
.money {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.btn {
  line-height: 44px;
  color: #fff;
  text-align: center;
  width: 86%;
  height: 44px;
  left: 7%;
  font-size: 14px;
  bottom: 45px;
  background: #2dd070;
  border-radius: 12px;
  position: fixed;
}
.btn:disabled {
  opacity: 0.35;
}
.mengceng {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  background: #333;
}
.cashoutFailBox {
  box-sizing: border-box;
  position: fixed;
  top: calc(50vh - 74px);
  left: 8%;
  width: 280px;
  padding: 24px;
  background: #fff;
  height: auto;
  border-radius: 16px;
  text-align: center;
  width: 84%;
}
.cashoutFailBox p:nth-child(1) {
  margin: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
  line-height: 22px;
}
.cashoutFailBox p:nth-child(2) {
  margin-bottom: 20px;
  /* font-weight: bold; */
  font-size: 13px;
  line-height: 18px;
  color: #999;
}
.cashoutFailBox .textbtn {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2dc84e;
}
</style>
